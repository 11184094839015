<template>
  <v-app id="chat" v-if="isVisible" v-resize="setDisplayWindowSize">
    <v-card tile flat>
      <v-navigation-drawer
        v-model="navigationDrawerState"
        app
        :hide-overlay="!smAndDown"
        :right="!smAndDown"
        bottom
        width="100%"
        :height="!smAndDown ? 'calc(100% - 80px)' : '100%'"
        style="background-color: transparent; z-index: 9;"
        :style="smAndDown ? '' : 'pointer-events: none; top: auto;'"
        ref="chatWindow"
        :class="smAndDown ? 'pa-1' : ''"
        touchless
        temporary
        :mobile-breakpoint="3000"
      >
        <vue-draggable-resizable
          v-if="!smAndDown"
          :active="!isMaximizeWindow"
          :prevent-deactivation="true"
          :w="chatWindow.width"
          :h="chatWindow.height"
          :x="chatWindow.x ? chatWindow.x : rightEdgeInitialValue"
          :y="chatWindow.y ? chatWindow.y : bottomEdgeInitialValue"
          :z="5"
          :min-width="chatWindow.minWidth"
          :min-height="chatWindow.minHeight"
          @dragging="onDrag"
          @resizing="onResize"
          :draggable="!isMaximizeWindow"
          :resizable="!isMaximizeWindow"
          :parent="true"
          class="rounded-lg"
          style="pointer-events: auto; background-color: white"
          drag-cancel=".drag-cancel"
        >
          <vb-ai-chat>
            <template #chat-window-header>
              <v-list-item dense class="py-2 pl-6 pr-4 rounded-lg rounded-b-0" style="background-color: #2c2c2c; cursor: all-scroll;">
                <v-list-item-title class="caption text-body-2 white--text">バリューブックスの選書AI</v-list-item-title>
                <v-btn
                  fab
                  x-small
                  max-width="24"
                  max-height="24"
                  color="white"
                  class="mr-2"
                  @click="maximizeWindowActive"
                  retain-focus-on-click
                >
                  <v-icon small>mdi-arrow-top-left-bottom-right</v-icon>
                </v-btn>
                <v-btn
                  fab
                  x-small
                  max-width="24"
                  max-height="24"
                  color="white"
                  @click.stop="closeNavigationDrawer"
                  retain-focus-on-click
                >
                  <v-icon small>mdi-minus</v-icon>
                </v-btn>
              </v-list-item>
            </template>
          </vb-ai-chat>
        </vue-draggable-resizable>

        <template v-else>
          <vb-ai-chat>
            <template #chat-window-header>
              <v-list-item dense class="py-2 pl-6 pr-4 rounded-lg rounded-b-0" style="background-color: #2c2c2c; cursor: all-scroll;">
                <v-list-item-title class="caption text-body-2 white--text">バリューブックスの選書AI</v-list-item-title>
                <v-btn
                  fab
                  x-small
                  max-width="24"
                  max-height="24"
                  color="white"
                  @click.stop="closeNavigationDrawer"
                  retain-focus-on-click
                >
                  <v-icon small>mdi-minus</v-icon>
                </v-btn>
              </v-list-item>
            </template>
          </vb-ai-chat>
        </template>

      </v-navigation-drawer>
    </v-card>

    <v-speed-dial
      v-if="!getIsDisplayAiChatNavbar"
      absolute
      bottom
      fixed
      right
      class="mr-md-2"
      :class="smAndDown ? 'mb-n3' : 'mb-n2'"
    >
      <template v-slot:activator>
        <v-card
          @click="openNavigationDrawer"
          flat
          :ripple="false"
          class="ai-balloon d-flex flex-md-column align-center"
          color="transparent"
          style="cursor: pointer; opacity: 1;">
          <v-img
            contain
            class="mt-n3"
            :src="cdnUrl + '/assets/images/item/btn-ai-search.svg'"
            :width="smAndDown ? 98 : 118"
            :height="smAndDown ? 100 : 119"
          />
        </v-card>
      </template>
    </v-speed-dial>
    <audio-controller v-if="!isAudioControllerExists" />
  </v-app>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import vbAiChat from "../molecules/vb-ai-chat.vue";
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import AudioController from "../molecules/item/audio-controller.vue";

const {mapState, mapGetters, mapActions, mapMutations} = createNamespacedHelpers('openAi');
const chatWindowWidth = 480;
const chatWindowHeight = 583;

export default {
  name: "vb-ai-chat-window",
  components: {AudioController, VueDraggableResizable, vbAiChat},
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      chatWindow: {
        width: chatWindowWidth,
        height: chatWindowHeight,
        minWidth: 280,
        minHeight: 290,
        x: null,
        y: null,
        beforeX: null,
        beforeY: null,
      },
      displayWindowSize: {
        width: 0,
        height: 0,
      },
      isMaximizeWindow: false,
      chatWindowKey: 0,
      isAudioControllerExists: true,
    }
  },
  provide: function () {
    return {
      sendEvent: this.sendEventToGoogleAnalytics
    }
  },
  methods: {
    ...mapMutations([
      'mutateIsDisplayAiChatNavbar',
    ]),
    openNavigationDrawer() {
      this.mutateIsDisplayAiChatNavbar(true);
      if(this.smAndDown) {
        document.documentElement.className = 'overflow-y-hidden';
      }
    },
    closeNavigationDrawer() {
      this.mutateIsDisplayAiChatNavbar(false);
      if(this.smAndDown) {
        document.documentElement.classList.remove('overflow-y-hidden');
      }
    },
    maximizeWindow() {
      this.chatWindow.width = this.displayWindowSize.width - 16;
      this.chatWindow.height = this.displayWindowSize.height - 80 - 16;
      this.chatWindow.x = 8;
      this.chatWindow.y = 8;
    },
    onResize: function (x, y, width, height) {
      this.chatWindow.x = x
      this.chatWindow.y = y
      this.chatWindow.width = width
      this.chatWindow.height = height
    },
    onDrag: function (x, y) {
      this.chatWindow.x = x
      this.chatWindow.y = y
    },
    // スクロールバーを除いた画面サイズを取得
    setDisplayWindowSize() {
      this.displayWindowSize.width = document.documentElement.clientWidth;
      this.displayWindowSize.height = document.documentElement.clientHeight;
    },
    maximizeWindowActive() {
      this.isMaximizeWindow = !this.isMaximizeWindow;
      this.chatWindowKey = this.chatWindowKey ? 0 : 1;
      if(this.isMaximizeWindow) {
        this.chatWindow.beforeX = this.chatWindow.x ? this.chatWindow.x : this.rightEdgeInitialValue;
        this.chatWindow.beforeY = this.chatWindow.y ? this.chatWindow.y : this.bottomEdgeInitialValue;
        this.maximizeWindow();
      } else {
        this.chatWindow.width = chatWindowWidth;
        this.chatWindow.height = chatWindowHeight;
        setTimeout(() => {
          this.chatWindow.x = this.chatWindow.beforeX;
          this.chatWindow.y = this.chatWindow.beforeY;
        }, 0);
      }
    },
    // Google Analyticsにイベントを送信
    sendEventToGoogleAnalytics(response) {
      gtag('event', 'QuestionToOpenAiByChatWindow', {
        "question_by_chat_window": response,
      });
    },
    checkAudioControllerExists() {
      // audio#audioControllerが存在するかどうか
      this.isAudioControllerExists = document.querySelectorAll('#audioController').length > 0;
    },
  },
  computed: {
    ...mapGetters([
      'getIsDisplayAiChatNavbar',
    ]),
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    rightEdgeInitialValue() {
      return this.displayWindowSize.width - this.chatWindow.width - 8;
    },
    bottomEdgeInitialValue() {
      return this.displayWindowSize.height - this.chatWindow.height - 88;
    },
    navigationDrawerState: {
      get() {
        return this.getIsDisplayAiChatNavbar;
      },
      set(value) {
        this.mutateIsDisplayAiChatNavbar(value);
      }
    },
    currentRoute() {
      return this.$route.path;
    },
    // 非表示対象のルートがある場合はtrueを返す
    isVisible() {
      const hiddenRoutes = [
        '/confirm',
        '/completed',
        '/shipping/form',
        '/shipping/confirm',
        '/card/form',
      ];
      return !hiddenRoutes.includes(this.currentRoute);
    }
  },
  mounted() {
    this.setDisplayWindowSize();
    this.checkAudioControllerExists();
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-application--wrap {
    min-height: unset !important;
  }
  .v-navigation-drawer {
    box-shadow: unset !important;
    &--bottom.v-navigation-drawer--is-mobile {
      max-height: unset;
    }
    &__border {
      display: none;
    }
  }

  .vdr {
    border: 0 none;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.3);
    .handle {
      border: 0 none;
      width: 8px;
      height: 8px;
      background-color: transparent;
    }
    .handle-tm,
    .handle-bm {
      width: 100%;
      left: 5px;
    }
    .handle-mr,
    .handle-ml{
      height: 100%;
      top: 5px;
    }
    .handle-tl {
      top: -8px;
      left: -8px;
    }
    .handle-tm {
      top: -8px;
    }
    .handle-tr {
      top: -8px;
      right: -8px;
    }
    .handle-mr {
      right: -8px;
    }
    .handle-br {
      bottom: -8px;
      right: -8px;
    }
    .handle-bm {
      bottom: -8px;
    }
    .handle-bl {
      bottom: -8px;
      left: -8px;
    }
    .handle-ml {
      left: -8px;
    }
  }
}
</style>